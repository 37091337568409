import React from "react"

import { Flex } from "theme-ui"
import Text from "../components/base/text/text"

const Contact = () => {
  return (
    <Flex sx={{ justifyContent: "center", alignItems: "center", flex: 1 }}>
      <Flex
        sx={{
          flexDirection: " column",
          textAlign: "center",
          a: {
            color: "darkGreen",
            cursor: "pointer",
            textDecoration: "none",
          },
        }}
      >
        <Text>
          For all inquiries, please contact{" "}
          <a href="mailto:hello@palmes.co">hello@palmes.co</a>.
        </Text>
        <Text>
          For everything else, please click{" "}
          <a href="https://www.youtube.com/watch?v=JXHx-khchk4&ab_channel=WimbledonWimbledon">
            here
          </a>
        </Text>
      </Flex>
    </Flex>
  )
}

export default Contact
